import {isRouteErrorResponse, useRouteError} from '@remix-run/react';
import {captureRemixErrorBoundaryError} from '@sentry/remix';
import {IconMoodSadDizzy} from '@tabler/icons';
import {ErrorPage} from '~/components/ErrorPage';

export enum PageExceptions {
  POLICY_ACCESS_DENIED = 'Access to policy denied',
  POLICY_PARSE_ERROR = 'Failed to parse policy data',
  DELEGATIONS_FETCH_ERROR = 'Failed to fetch account delegations',
  TOTAL_STAKE_FETCH_ERROR = 'Failed to fetch total stake',
  ACCOUNT_IS_DISABLED_ERROR = 'Account is not active. Please renew a subscription.',
}

const descriptions: Record<PageExceptions, string> = {
  [PageExceptions.POLICY_ACCESS_DENIED]: 'You are not allowed to access this policy.',
  [PageExceptions.POLICY_PARSE_ERROR]:
    'We are unable to render this policy. We are already aware of this issue and working on fixing it.',
  [PageExceptions.DELEGATIONS_FETCH_ERROR]:
    'We are unable to retrieve delegations of your account. Please make sure the configured RPC endpoints are working.',
  [PageExceptions.TOTAL_STAKE_FETCH_ERROR]:
    'We are unable to retrieve total stake of your account. Please make sure the configured RPC endpoints are working.',
  [PageExceptions.ACCOUNT_IS_DISABLED_ERROR]: 'Account is not active. Please renew a subscription.',
};

function isPageException(title: string): title is PageExceptions {
  return Object.values(PageExceptions).includes(title as PageExceptions);
}

export function ErrorBoundary() {
  const error = useRouteError();

  console.log(error);

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    const {status, data} = error;
    const text = typeof data === 'string' ? data : 'Unexpected error occurred';

    if (text === PageExceptions.POLICY_PARSE_ERROR) {
      return (
        <ErrorPage
          errorCode={status}
          title={text}
          description={descriptions[text]}
          redirectTo="/app/create-policy"
          redirectLabel="Create new policy"
        />
      );
    }

    if (isPageException(text)) {
      return <ErrorPage errorCode={status} title={text} description={descriptions[text]} />;
    }
  }

  return (
    <ErrorPage
      errorCode={<IconMoodSadDizzy size={200} />}
      title="Unexpected error occured"
      description="Smart Delegation App is unavailable due to unexpected error. We are already aware of this issue and working on fixing it."
      redirectTo=""
    />
  );
}
